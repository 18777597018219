.container{
    width: 100%;
    padding: 1rem;
    display: flex;
    min-height: calc(100vh - 73px);
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
}

.wrapper{
    width: 50%;
    padding: 2rem;
    height: fit-content;
    border-radius: 12px;
    background-color: #fff;
    margin: 3rem;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
}

form{
    width: 100%;
}

.formContainer{
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.msgSignUp{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.msgSignUp p{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}

.msgSignUp h1{
    font-size: 34px;
    font-weight: 700;
    text-align: center;
}

.inputSignupField{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inputSignupField div{
    width: 100%;
    position: relative;
}
.inputSignupContainer{
    width: 100%;
    padding: 0 1rem;

}

.inputSignupField div label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: rgb(64,62,61);
    transition: all 250ms ease-in-out;

}
input{
    width: 100%;
    border: 1px solid black;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
}

input:focus ~ label,input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

.checkBox{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.checkBox input{
    width: fit-content;
    width: 20px;
    height: 20px;
    color: rgb(64,62,61);    
}

.checkBox span{
    font-size: 16px;
    color: black;
}

.signUpBtn{
    display: flex;
    justify-content: center;
}

.signUpBtn button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}

.signUpBtn button:hover{
    background-color: rgb(252, 208, 131);
}

.haveAcc{
    display: flex;
    margin-top: -10px;
    justify-content: center;
}
.haveAcc span a{
    color: black;
    font-weight: 700;
}

.hidePassword i{
    position: absolute ;
    top:-34px ;
    right: 20px;
    cursor: pointer;
}

@media screen  and (max-width:930px){
    .wrapper{
        width: 75%;
    }   
}

@media screen  and (max-width:760px){
    .wrapper{
        width: 95%;
    }   
    .inputSignupContainer{
        padding: 0;
    }
}

@media screen  and (max-width:600px){
    .wrapper{
        width: 100%;
        padding: 0;
    }   
    .msgSignUp h1{
        font-size: 28px;
    }
    .msgSignUp p{
        font-size: 16px;
    }

    .checkBox span{
        font-size: 12px;
    }
}

@media screen  and (max-width:450px){
       .wrapper{
        margin: 3rem 0;
       }
       .formContainer{
        padding:2rem 1rem;
       }
}

.disableBtn{
    cursor: not-allowed !important;
}