.agentContainer{
    width: 100%;
    padding: 2rem;
    align-items: center;
    min-height: calc(100vh - 73px);
    display: flex;
    justify-content: center;
    background-color: #f3f3f3;
}

.agentWrapper{
    width: 80%;
    padding: 2rem;
    border-radius: 12px;
    background-color: #fff;
    margin: 3rem;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
}

form {
    width: 100%;
    margin-top:2rem;
}
.agentMessage{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.agentMessage p{
    font-weight: 700;
}

.agentFormData{
    display: flex;
    gap: 2rem;
    width: 100%;
}

.agentLeft,.agentRight{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.agentNow button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}

.agentNow button:hover{
    background-color: rgb(252, 208, 131);
}

.agentNow{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
input{
    width: 100%;
    border: 1px solid black;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
}
.agentLeft div label,.address label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: rgb(64,62,61);
    transition: all 250ms ease-in-out;

}

.agentLeft div{
    position: relative;
}


input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

.agentRight select{
    width: 100%;
    outline: none;
    padding: 0.9rem 1rem;
    font-weight: bold;
    font-size: 18px;
    border-radius: 13px;
    color: rgb(64,62,61);
}


select option{
    font-weight: bold;
    font-size: 14px;
    color: rgb(64,62,61);
    padding: 0.5rem 1rem;
}

.address{
    width: 100%;
    position: relative;
    margin-top: 1.5rem;
}

.address textarea{
    width: 100%;
    resize: none;
    height: 100%;
}

.file{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:0.5rem
}
.file label{
    font-weight: 700;
    margin-bottom: 3px !important;
}
input[type="checkbox"]{
    width: fit-content;
}
.question{
    display: flex;
    align-items: center;
    gap:1rem;
    margin: 0.6rem 0;
}

.question span{
    font-weight: 700;
}

.disableBtn{
    cursor: not-allowed !important;
}
.hidePassword i{
    position: absolute ;
    top:-34px ;
    right: 20px;
    cursor: pointer;
}

.normal{
    border: 1px solid black;
}

.fileError{
    border: 2px solid red;
}

@media screen  and (max-width:850px){
    .agentWrapper{
        width: 100%;
    }
}
@media screen  and (max-width:750px){
    .agentWrapper{
        margin: 3rem 0;
    }
}
@media screen  and (max-width:650px){
    .agentFormData{
        flex-direction: column;
    }
    .address{
        margin-top: 0;
    }
    .mt{
        margin-top: 1.5rem;
    }
}
@media screen  and (max-width:510px){
    .agentWrapper{
        padding: 2rem 1rem;
    }
    .agentContainer{
        padding: 2rem 1rem;
    }

    .agentMessage h1{
        width: 100%;
        text-align: center;
    }
}