.homeContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f3f3f3;
    min-height: calc(100vh - 73px);
    padding-bottom: 3rem;
}

.homeWrapper{
    width: 55%;
}

.homeMessage h1{
    font-weight: 700;
    color: rgb(64,62,61);;
}
.homeMessage{
    padding: 2rem;
    margin: 2rem 0;
}

.application{
    display: flex;
    width: 100%;
    align-items: center;
    gap:2rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    cursor: pointer;
    border-radius: 12px;
    padding: 2rem;
}

.application .imgContainer{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:1px solid red;

}
.homeOptions{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.application .imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    user-select: none;

}
.infoApplication{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1 1;
}

.price{
    display: flex;
    flex-direction: column;
    gap:0.3rem;
    align-items: center;
}
.application h1{
    font-size: 22px;
    user-select: none;
    color: rgb(64,62,61);;
}

@media screen and (max-width:1000px){
    .homeWrapper{
        width: 65%;
    }
}
@media screen and (max-width:945px){
    .homeWrapper{
        width: 70%;
    }
}

@media screen and (max-width:640px){
    .homeWrapper{
        width: 90%;
    }
}
@media screen and (max-width:480px){
    .homeWrapper{
        width: 90%;
    }
    .homeMessage h1{
        font-size: 25px;
    }
    .homeMessage{
        margin: 2rem  0;
        padding: 1rem;
    }
    .homeOptions{
        padding: 1rem 0;
    }
    .application h1{
        font-size: 18px;
    }
    .application{
        padding: 2rem 1rem;
        gap: 1.5em;
    }
}

@media screen and (max-width:360px){
    .application{
        gap:1.5rem;
    }
    .infoApplication{
        align-items: center;
    }
    .infoApplication h1{
        text-align: center;
    }
    .infoApplication span{
        text-align: center;
    }
}