.navbar{
    width: 100%;
    height: 73px;
    border-bottom:3px solid black ;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1rem    
}
.logoBtn{
    padding: 0.4rem 0.5rem;
    border-radius:45%;
    font-weight: bolder;
    font-size: 0.9rem;
    font-size: 20px;    
    color: black;
    font-weight: 700;
    text-decoration: none;
}

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f3f3f3;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: -10px;
    border-radius: 12px;
    padding: 1rem 0;
  }

  .bookNoW:hover,.contactBtn:hover,.logOutBtn button:hover{
    background-color: rgb(252, 208, 131);
    }

  .dropdownContent a {
    float: none;
    color: #04b4fc;
    padding: 6px 16px;
    font-weight: 700;
    font-size: 15px;
    text-decoration: none;
    display: block;
    border-radius: 12px;
    text-align: center;

  }

  .dropdownContent a:hover {
    border-radius: 12px;
    background-color: rgb(252, 208, 131);
  }
  
  .dropdown:hover .dropdownContent {
    display: block;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }
  
  .dropdown .dropBtn {
    background: transparent;
    background-blend-mode: soft-light, normal;
    height: 38px;
    min-width: 100px;
    text-decoration: none;
    display: flex;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    color: #04b4fc;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 0px 15px;
    line-height: 100%;
    border: none;
    border-bottom: 4px solid #E9703E;
    -webkit-user-select: none;
    user-select: none;
  }

.desktop{
    flex:1 1;
}

.desktopWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap:1rem;
}

.loginDesktopHamBurger{
    display: none;
}

.buttonContainer{
    display: flex;
    gap: 0.9rem;
    align-items: center;
    justify-content: flex-end; 
}

.logOutBtn button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 38px;
    min-width: 100px;
    padding: 0px 15px;
    line-height: 100%;
}
.buttonContainer button:hover{
    background-color: rgb(252, 208, 131);
}

.contactBtn , .bookNoW{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background:transparent;
    background-blend-mode: soft-light, normal;
    height: 38px;
    min-width: 100px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 0px 15px;
    line-height: 100%;
    border-bottom: 4px solid #E9703E;
    user-select: none;
}

.contactBtn a,.bookNoW a{
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    color: #04b4fc;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}



.logoImg{
    width: 40px;
    height: 40px;
}

.logoImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logoContainer{
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.hamburgerContainer{
    display: none;
}

.hamburgerMenu{
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    padding: 6rem 2rem 2rem 2rem;
    z-index: 99;
    display: flex;
    flex-direction: column;
    gap:1rem;    
    width: 40%;
    align-items: center;
    background-color: rgba(225,225,225);
    transform: translateX(-100vw);
    transition: all 500ms ease-in-out;
    opacity: 0;
}

.showMenu{
    transform: translateX(0);
    opacity: 1;
}
.hideMenu{
    transform: translateX(-100vw);
    opacity: 0;
}

.icon{
    display: none;
}
.icon h3{
    text-align: end;
    cursor: pointer;
    user-select:none;
    margin-left: 10px;
    font-size: 1.5rem;
    font-weight: 700;
}

.close{
    position: absolute;
    right: 30px;
    top:15px;
}
.close span{
    font-weight: 700;
    font-size: 1.5rem;
    cursor: pointer;
}


.logOutDesktopWrapper{
    width: 100%;
}
.mobileContactLogin{
    display: none;
}

.logOutHamburger{
    display: none;
}

@media screen and (max-width:740px) {
    .logoBtn{
        display: none;
    }
}

@media screen and (max-width:580px) {

    .desktopLogOut{
        display: block !important;
    }
    .hamburgerMenu{
        width: 65%;
    }
    .logOutDesktop{
        display: none;
        
    }
    .mobileWrapper{
        display: none;
    }
    .logOutHamburger{
        display: block;
    }

    .navbar{
        padding: 0 1rem;
        gap:0.5rem;
    }

    .logoImg{
        width: 30px;
        height: 30px;
    }
    .logoContainer{
        gap:0.2rem;
    }
    .logOutBtn button{
        font-size: 1rem;
        height: 33px;
        padding: 0 12px;
        min-width: 70px;
    }
}

@media screen and (max-width:1000px) {
    .desktop{
        display: none;
    }
    .loginDesktopHamBurger{
        display: block;
        flex: 1 1;
    }
    .desktopLogOut{
        display: none;
    }
    .loginDesktopHamBurgerWrapper{
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 1rem;
    }

    .hamburgerContainer{
        display: block;
    }
    .icon{
        display: block;
    }

    .mobileContactLogin{
        display: block;
        display: flex;
        gap:1rem;
    }
    .logOutBtn{
        flex: 1 1;
        text-align: end;
    }
    .hamburgerMenu .bookNoW{
        min-width: 150px;
    }

    .hamburgerMenu .contactBtn{
        min-width: 150px;
    }
}