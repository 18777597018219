.homeContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f3f3f3;
    min-height: calc(100vh - 73px);
}

.homeWrapper{
    width: 55%;
    padding-bottom: 2rem;
}
.appLoad{
    width: 100%;
    text-align: center;
}

.homeMessage h1{
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: rgb(64,62,61);;
}
.homeMessage{
    padding: 2rem;
    margin: 2rem 0;
}

.application{
    display: flex;
    width: 100%;
    align-items: center;
    gap:2rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    cursor: pointer;
    border-radius: 12px;
    padding: 2rem;
}

.application .imgContainer{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:1px solid red;
}

.homeOptions{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.application .imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    user-select: none;

}
.infoApplication{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
    flex:1 1;
}
.status h3,.payment h3{
    color:#E9703E;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
}

.rightPart{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:0.6rem;
}
.status span,.payment span{
    font-weight: 700;
}

.payment button{
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 700;
    height: 22px;
    min-width: 70px;
    padding: 0px 10px;
    line-height: 100%;
}

.payment button:hover{
    background-color: rgb(252, 208, 131) !important;
}

.application h1{
    font-size: 22px;
    user-select: none;
    color: rgb(64,62,61);;
}
.startApplication{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    text-decoration: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 38px;
    min-width: 100px;
    padding: 0px 20px;
    line-height: 100%;
    margin-top: 2rem;
}

.startApplication:hover{
    background-color: rgb(252, 208, 131);
}

.rightPart{

}

@media screen and (max-width:1000px){
    .homeWrapper{
        width: 65%;
    }
}

@media screen and (max-width:945px){
    .homeWrapper{
        width: 70%;
    }
}

@media screen and (max-width:640px){
    .homeWrapper{
        width: 90%;
    }
}
@media screen and (max-width:480px){
    .homeWrapper{
        width: 90%;
    }
    .homeMessage h1{
        font-size: 25px;
    }
    .homeMessage{
        margin: 2rem  0;
        padding: 1rem;
    }
    .homeOptions{
        padding: 1rem 0;
    }
    .application h1{
        font-size: 18px;
    }
    .application{
        padding: 2rem 1rem;
    }
}

@media screen and (max-width:365px){
   .appLoad{
    font-size: 1.5rem;
   }
    .application{
        gap:1.5rem;
    }
    .infoApplication{
        align-items: center;
    }
    .infoApplication h1{
        text-align: center;
    }
    .infoApplication span{
        text-align: center;
    }
}