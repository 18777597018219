.contactContainer{
    width: 100%;
    padding: 1rem;
    display: flex;
    height: calc(100vh - 73px);
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
}

.contactWrapper{
    width: 50%;
    padding: 2rem;
    border-radius: 12px;
    background-color: #fff;
    margin: 3rem;
    height: fit-content;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
}

form{
    width: 100%;
}

.contactWrapper h1{
    text-align: center;
}

.contactData div{
    width: 100%;
    position: relative;
}

.contactData div label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: rgb(64,62,61);
    transition: all 250ms ease-in-out;

}

input:focus ~ label,input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}


.enquiry button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}

.enquiry button:hover{
    background-color: rgb(252, 208, 131);
}
.disableBtn{
    cursor: not-allowed !important;
}
.enquiry{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.contactData{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
textarea:focus ~ label,
textarea:valid ~ label{
    transform: translateY(-35px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}

input,textarea{
    width: 100%;
    border: 1px solid black;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
}

.msg textarea{
    width: 100%;
    resize: none;
    height: 70px;
}


.popupContainer{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupWrapper{
    background-color: #fff;
    padding:3rem;
    cursor:default;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    gap:1rem
}

.popupBtns{
    display: flex;
    justify-content: space-between;
}

.popupBtns a{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    text-decoration: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    font-family: FuturaPT, sans-serif;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    min-width: 70px;
    padding: 0px 15px;
    line-height: 100%;
    margin-top: 2rem;
}
.popupBtns a:hover{
    background-color: rgb(252, 208, 131);
}


@media screen and (max-width:945px){
    .contactWrapper{
        width: 70%;
    }
}

@media screen and (max-width:640px){
    .contactWrapper{
        width: 90%;
    }
}

@media screen and (max-width:480px){
    .contactWrapper{
        width: 100%;
        margin: 3rem 0;
        padding: 2rem 1rem;
    }
}