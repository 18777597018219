.AppWrapper{
    background-color: #f3f3f3;
    width: 100%;
    min-height: calc(100vh - 73px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.form{
    margin: 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem ;
}
.FormGroup{
    border: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.FormRow{
    display: flex;
    flex-direction: column;
    gap:0.3rem;
}

.FormRow label{
    color: black;
}

.FormRow input{
    padding: 8px 13px;
    border:2px solid black;
}
.productInfo{
    width: 100%;
}
.ElementsApp{
    margin-top: 1rem !important;
}

.SubmitButton {
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}
.SubmitButton:hover{
    background-color: rgb(252, 208, 131);
}
.checkContainerWrapper{
    display: flex;
    padding: 2rem;
    width: 50%;
    flex-direction: column;
    align-items: center;
}

.companyHeader{
    display: flex;
    flex-direction: column;
    gap:1rem;
    width: 100%;
    align-items: center;
    margin-bottom: 1.5rem;
}
.companyHeader .logo{
    width: 100px;
    height: 100px;
}
.companyHeader .logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.application{
    display: flex;
    width: 100%;
    align-items: center;
    gap:2rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    cursor: pointer;
    border-radius: 12px;
    padding: 2rem;
}

.application .imgContainer{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:1px solid red;
}

.homeOptions{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.application .imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    user-select: none;

}
.infoApplication{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
    flex:1 1;
}
.status h3{
    color:#E9703E
}
.application h1{
    font-size: 22px;
    user-select: none;
    color: rgb(64,62,61);;
}
.checkoutBtn{
    margin-top: 2rem;
}

.container-pay{
    background-color: #f3f3f3;
    width: 100%;
    min-height: calc(100vh - 73px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.proceed-msg{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.proceed-msg button{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 20px;
    line-height: 100%;
}


.proceed-msg button:hover{
    background-color: rgb(252, 208, 131);
}

.proceed-msg h2{
    width: 100%;
    text-align: center;
    font-weight: 700;
}

.Result{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
}
.back-to-app button{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}

.back-to-app button:hover{
    background-color: rgb(252, 208, 131);
}



@media screen and (max-width:1000px){
    .checkContainerWrapper{
        width: 75% !important;
    }
}

@media screen and (max-width:750px){
    .checkContainerWrapper{
        width: 90% !important;
    }
}

@media screen and (max-width:580px){
    .checkContainerWrapper{
        padding: 2rem 0;
    }
    .checkContainerWrapper{
        width: 100% !important;
    }
    .proceed-msg h2{
        font-size: 1.3rem;
    }
}

@media screen and (max-width:480px){
    .application h1{
        font-size: 18px;
    }
    .application{
        padding: 2rem 1rem;
    }
    .AppWrapper{
        padding: 2rem 1rem;
    }
    .ResultMessage h4{
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width:420px){
   
    .application{
        gap:1.5rem;
    }
    .infoApplication{
        align-items: center;
    }
    .infoApplication h1{
        text-align: center;
    }
    .infoApplication span{
        text-align: center;
    }
    .proceed-msg h2{
        font-size: 1.1rem;
    }
}
