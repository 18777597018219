.infoContainer{
    width:100%;
    min-height: calc(100vh - 197.49px);
    background-color: #f3f3f3;

}

.infoWrapper{
    width: 50%;
    margin: auto;
    display:flex;
    min-height: calc(100vh - 197.49px);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap:2rem;
    padding: 2rem 1rem;
}

.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1rem;
}

.tile{
    width: fit-content;
    border: 2px solid black;
    outline: none;
    padding: 0.8rem 1rem 0.8rem 1rem;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
    display: flex;
    align-items: center;
    gap:1rem;
}

.key{
    min-width: 185px;
    font-size: 1rem;
    font-weight: 700;
}
.value{
    min-width: 185px;
    font-size: 0.9rem;
    font-weight: 600;
}

  .folder{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    text-decoration: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 38px;
    min-width: 100px;
    padding: 0px 20px;
    line-height: 100%;
    margin-top:2rem;
}

.folder:hover{
    background-color: rgb(252, 208, 131);
}



.value button{
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 700;
    height: 22px;
    min-width: 70px;
    padding: 1px 10px;
    line-height: 100%;
}

.value button:hover{
    background-color: rgb(252, 208, 131) !important;
}
  .appStatus{
    padding: 0.4rem 1rem;
    border-radius: 12px;
    border: 2px solid black;
    font-weight: 700;
  }

  .appStatus option{
    
  }

  @media (max-width: 990px) {
    .infoWrapper{
        width: fit-content;
    }
  }

  @media (max-width: 500px) {
    .tile{
        flex-direction: column;
        gap:0.5rem;
        width: 100%;
        align-items: flex-start;
    }
    .infoWrapper{
        width: 90%;
    }
    .key{
        min-width: auto;
  
    }
    .value{
        min-width: auto;
    }
  }
  @media (max-width: 415px) {
    .folder{
       font-size: 0.9rem;
    }
  }
  @media (max-width: 395px) {
    .infoWrapper h1{
       font-size: 1.8rem;
    }
  }