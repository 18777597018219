.agentContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background-color: #f3f3f3;
    min-height: calc(100vh - 197.49px);
}
.myFilesBtn button{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 38px;
    min-width: 120px;
    padding: 0px 15px;
    line-height: 100%;
}
.myFilesBtn button:hover{
    background-color: rgb(252, 208, 131);
}