.container{
    width: 100%;
    padding: 2rem;
    gap:2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 73px);
    justify-content: center;
    background-color: #f3f3f3;
}
.wrapper{
    width: 80%;
    padding: 2rem;
    border-radius: 12px;
    background-color: #fff;
    margin: 0 0 3rem 0;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
}

.wrapper h1{
    width: 100%;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
}

form {
    width: 100%;
    margin-top:2rem;
}
.stepFirst{
    width:100%;
}
.containerData{
    display: flex;
    gap: 2rem;
    width: 100%;
}


.application{
    display: flex;
    width: 80%;
    align-items: center;
    gap:2rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    cursor: pointer;
    border-radius: 12px;
    padding: 2rem;
}

.application .imgContainer{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:1px solid red;

}
.homeOptions{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.application .imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
    user-select: none;

}
.infoApplication{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1 1;
}

.price{
    display: flex;
    flex-direction: column;
    gap:0.3rem;
    align-items: center;
}
.application h1{
    font-size: 22px;
    user-select: none;
    color: rgb(64,62,61);;
}


.left,.right{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.address{
    width: 100%;
    position: relative;
    margin: 2rem 0;
}

.address textarea{
    width: 100%;
    resize: none;
    height: 100%;
}

.next{
    display: flex;
    justify-content: center;
    align-items: center;
}

.next button,.prev button,.submit button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}

.next button:hover,.prev button:hover,.submit button:hover{
    background-color: rgb(252, 208, 131);
}

.left div,.right div{
    width: 100%;
    position: relative;
}

input,textarea{
    width: 100%;
    border: 1px solid black;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
}

.left div label,
.right div label,
.address label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: rgb(64,62,61);
    transition: all 250ms ease-in-out;

}

input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}
textarea:focus ~ label,
textarea:valid ~ label{
    transform: translateY(-35px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}


.left select,.right select{
    width: 100%;
    outline: none;
    padding: 0.9rem 1rem;
    font-weight: bold;
    font-size: 18px;
    border-radius: 13px;
    color: rgb(64,62,61);
}


select option{
    font-weight: bold;
    font-size: 14px;
    color: rgb(64,62,61);
    padding: 0.5rem 1rem;
}

.mobile{
    display: none;
}

.desktop{
    display: block;
}


input[type="file"]{
    padding: 0.5rem;
    font-size: 14px;
    font-weight: normal;
}
.fileWrapper{
    display: flex;
    gap:2rem;
}

.leftFile,.rightFile{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.leftFile label,.rightFile label{
    font-size: 16px;
    font-weight: bold;
}
.leftFile div,.rightFile div{
    display: flex;
    flex-direction: column;
    gap:0.4rem;
}

.submitDiv{
    display: flex;
    justify-content: center;
    gap:2rem;
    margin-top: 2rem;
}
.disableBtn{
    cursor: not-allowed !important;
}


.normal{
    border: 1px solid black;
}

.fileError{
    border: 2px solid red;
}

@media screen  and (max-width:950px){
    .wrapper{
        margin: 0 0 3rem 0;
        padding: 2rem 1rem;
    }
}

@media screen  and (max-width:900px){
    .wrapper{
        width: 100%;
    }
    .application{
        width: 100%;
    }
}

@media screen  and (max-width:750px){
    .wrapper{
        margin: 0;
    }
}

@media screen  and (max-width:650px){
    .containerData{
        flex-direction: column;
        gap: 1.5rem;
    }
    .left,.right{
        gap:1.5rem
    }
    .mobile{
        display: block;
    }
    .desktop{
        display: none;
    }
    .fileWrapper{
        flex-direction: column;
        gap: 1rem;
    }
    .address{
        margin-top: 1.5rem;
    }
}
@media screen  and (max-width:500px){
    .submitDiv{
        flex-direction: column;
        align-items: center;
        gap:1rem;

    }
}
@media screen  and (max-width:400px){
    .container{
        padding: 1rem;
    }
}

@media screen and (max-width:480px){
    .application h1{
        font-size: 18px;
    }
    .application{
        padding: 2rem 1rem;
        gap: 1.5em;
    }
}

@media screen and (max-width:532px){
    .application{
        gap:1.5rem;
    }
    .infoApplication{
        align-items: center;
    }
    .infoApplication h1{
        text-align: center;
    }
    .infoApplication span{
        text-align: center;
    }
}